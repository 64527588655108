





























































































































































































import { Vue, Component } from 'vue-property-decorator';

@Component
export default class MyPageMenuTiles extends Vue {
  readonly VUE_APP_EVENTOS_TOP_URL = process.env.VUE_APP_EVENTOS_TOP_URL;
  readonly VUE_APP_EVENTOS_MYPAGE_URL = process.env.VUE_APP_EVENTOS_MYPAGE_URL;

  // OPアプリ経由のアクセスであるか判定
  get isOpApp() {
    return this.$auth.isOpApp();
  }
}
