













































































































































































































































































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator';

@Component({
  components: {}
})
export default class HamburgerMenu extends Vue {
  readonly VUE_APP_EVENTOS_TOP_URL = process.env.VUE_APP_EVENTOS_TOP_URL;
  readonly VUE_APP_EVENTOS_MYPAGE_URL = process.env.VUE_APP_EVENTOS_MYPAGE_URL;

  isMenuOpen = false; //ハンバーガーメニューの表示フラグ

  //サブメニューの表示フラグ
  isSubMenuOpen: { [key: string]: boolean } = {
    op: false,
    odekake: false,
    user: false
  };

  //ハンバーガーメニュー内のサブメニュー開閉
  subMenuClick(type: string) {
    this.isSubMenuOpen[type] = !this.isSubMenuOpen[type];
  }

  //ログアウト
  logout() {
    // 明示的にLogout後のURLを指定
    const returnTo = `${window.location.origin}/`;
    this.$auth.logout({ returnTo });
  }
  //ルートパスが変更された場合にメニューを閉じる
  @Watch('$route.fullPath')
  closeMenu() {
    this.isMenuOpen = false;
  }

  //ログイン判定
  get isAuthenticatedOk() {
    return this.$auth.isAuthenticated && !this.$auth.loading;
  }

  // OPアプリ経由のアクセスであるか判定
  get isOpApp() {
    return this.$auth.isOpApp();
  }

  get logoutBtnLabel() {
    return this.$auth.isOpApp() ? 'アプリに戻る' : 'ログアウト';
  }
}
