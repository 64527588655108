

















































































































































































































































































































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator';

@Component({
  components: {}
})
export default class PersonalMenuBar extends Vue {
  readonly VUE_APP_EVENTOS_TOP_URL = process.env.VUE_APP_EVENTOS_TOP_URL;
  readonly VUE_APP_EVENTOS_MYPAGE_URL = process.env.VUE_APP_EVENTOS_MYPAGE_URL;

  readonly MENU_PAGES_NAMES: { [key: string]: Array<String> } = {
    TOP: ['home'],
    ODAKYU_POINT: ['odakyu-point'],
    ODEKAKE_POINT: ['odekake-point'],
    ENERGY: ['energy'],
    MY_MENU: [
      'news-list',
      'profile',
      'account-info',
      'manage-subscription',
      'events'
    ]
  };

  //パーソナルメニューカレントチェック
  isCurrentMenu(key: string) {
    const currentPage = this.$route.name ? this.$route.name : '';
    return this.MENU_PAGES_NAMES[key].includes(currentPage);
  }

  private get isAuthenticatedOk() {
    return this.$auth.isAuthenticated && !this.$auth.loading;
  }

  //固定メニューの表示フラグ
  isFixMenuOpen: { [key: string]: boolean } = {
    op: false,
    odekake: false,
    user: false
  };

  //固定メニューホバー（マウスホバーがあり、PCサイズの場合のみ動作）
  fixMenuMouseOver(type: string) {
    if (
      window.matchMedia('(min-width:1000px)').matches &&
      window.matchMedia('(min-width:1000px)').matches &&
      !window.matchMedia('(hover: none)').matches
    ) {
      this.isFixMenuOpen[type] = true;
    }
  }
  fixMenuMouseOut(type: string) {
    if (
      window.matchMedia('(min-width:1000px)').matches &&
      window.matchMedia('(min-width:1000px)').matches &&
      !window.matchMedia('(hover: none)').matches
    ) {
      this.isFixMenuOpen[type] = false;
    }
  }

  fixMenuClickEvent = 'click';

  //固定メニュークリック（マウスホバーが無いか、タブレット以下のサイズのみ動作）
  fixMenuClick(type: string) {
    if (
      window.matchMedia('(max-width:999px)').matches ||
      window.matchMedia('(hover: none)').matches
    ) {
      this.fixMenuClickEvent = '';

      //クリックしたメニュー以外は非表示にする
      for (const key of Object.keys(this.isFixMenuOpen)) {
        if (type !== key) {
          this.isFixMenuOpen[key] = false;
        }
      }

      this.isFixMenuOpen[type] = !this.isFixMenuOpen[type];
    } else {
      this.fixMenuClickEvent = 'click';
    }
  }

  //ルートパスが変更された場合にメニューを閉じる
  @Watch('$route.fullPath')
  closeFixMenu() {
    for (const key of Object.keys(this.isFixMenuOpen)) {
      this.isFixMenuOpen[key] = false;
    }
  }

  //dom構築後、描画が完了したタイミングで実施
  updated() {
    if (
      window.matchMedia('(max-width:999px)').matches ||
      window.matchMedia('(hover: none)').matches
    ) {
      this.fixMenuClickEvent = '';
    } else {
      this.fixMenuClickEvent = 'click';
    }
  }

  //ページが下にスクロールされたかの判定フラグ
  is_scroll_down = false;

  mounted() {
    var isScrollReady = false;
    var scroll_buffer = 0;

    const eventScroll = () => {
      if (isScrollReady) {
        return false;
      }

      isScrollReady = true;

      window.requestAnimationFrame(() => {
        this.is_scroll_down = scroll_buffer < window.scrollY ? true : false;
        scroll_buffer = window.scrollY;

        isScrollReady = false;
      });
    };

    window.addEventListener('scroll', () => {
      eventScroll();
    });

    eventScroll();
  }

  // OPアプリ経由のアクセスであるか判定
  get isOpApp() {
    return this.$auth.isOpApp();
  }
}
