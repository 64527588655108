// ポップアップ型 chatbot の画面ごとの表示制御に利用する

// chatbot を非表示にしたい画面で呼び出す
export const chatbotHide = () => {
  window.ul_widget('hide');
  return;
};

// chatbot を表示にしたい画面で呼び出す
export const chatbotShow = () => {
  window.ul_widget('show');
  return;
};
